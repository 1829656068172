<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <b>Obrigado!</b>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col class="text-center">
          <h3 class="success--text">Sua inscrição foi feita com sucesso!</h3>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
  export default {

    data: () => ({
      
    }),

    methods: {
      
    }
  }
</script>
